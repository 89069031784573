import 'react-phone-number-input/style.css';

import { AnchorButton, Button } from 'src/components/Button';
import { HeaderTitle, Paragraph } from 'src/components/Typography';

import MenuOpened from '../Icons/MenuOpened';
import PhoneInput from 'react-phone-number-input';
import colors from 'src/style/colors';
import fonts from 'src/style/fonts';
import { mediaQueries } from 'src/style/breakpoints';
import styled from 'src/style';

//Form Section

export const FormContainer = styled.div(
  {
    color: colors.purpleDark,
    zIndex: 1200,
    borderRadius: 10,
    textAlign: 'left',
    backgroundColor: colors.lightGrey,
  },
  ({ windowWidth, isContact }) => ({
    width: isContact ? 'auto' : windowWidth > 1500 ? '70vw' : '85vw',
    [mediaQueries.phoneOnly]: {
      width: isContact ? '80vw' : 'auto',
    },
    maxWidth: 1205,
  })
);

export const FormBlockSection = styled.div(
  {
    borderRadius: '0px 0px 10px 10px',
    '& p': {
      color: colors.purpleDark,
    },
    // padding: '35px 30px',
    // [mediaQueries.tabletLandscapeUp]: {
    //   padding: '55px 60px',
    // },
  },
  ({ modal, landing }) => ({
    backgroundColor: modal ? null : colors.white,
    borderRadius: modal || landing ? '10px' : '0px 0px 10px 10px',
    padding: modal ? '0px' : '35px 30px',
    position: modal && 'static',
    [mediaQueries.tabletLandscapeUp]: {
      padding: modal ? '0px' : landing ? '35px 30px' : '55px 60px',
    },
    [mediaQueries.desktopUp]: {
      padding: landing && '35px 30px 0px 30px',
    },
    [mediaQueries.desktopUp]: {
      maxWidth: landing ? '600px' : 'auto',
    },
  })
);

export const CloseModal = styled(MenuOpened)({
  position: 'absolute',
  top: 20,
  right: 20,
  cursor: 'pointer',
  zIndex: 1200,
});

// Form Elements

export const Label = styled(Paragraph)({
  ...fonts.HKCompakt.bold,
  color: colors.purpleDark,
  fontSize: 17,
  paddingBottom: 10,
  paddingTop: 0,
  [mediaQueries.phoneOnly]: {
    fontSize: 16,
  },
});

export const Sublabel = styled(Paragraph)({
  ...fonts.HKCompakt.medium,
  fontSize: 16,
  lineHeight: '22px',
  padding: '0px 0px 20px 0px',
});

export const InputForm = styled.input(
  {
    ...fonts.HKCompakt.medium,
    backgroundColor: colors.white,
    borderRadius: 5,
    // maxWidth: 460,
    width: '100%',
    height: 42,
    paddingLeft: 20,
    paddingRight: 20,
    outline: 'none',
    color: colors.purpleDark,
    [mediaQueries.phoneOnly]: {
      width: '100%',
    },
    [mediaQueries.tabletPortraitUp]: {
      width: '100%',
      minWidth: 220,
    },
    ':focus': {
      border: `1px solid ${colors.purple}`,
    },
  },
  ({ errorStyle, landing }) => ({
    border: errorStyle === true ? `1px solid red` : `1px solid ${colors.midGrey}`,
    [mediaQueries.tabletLandscapeUp]: {
      minWidth: landing ? 'none' : 280,
    },
  })
);

export const InputFormLarge = styled.input(
  {
    ...fonts.HKCompakt.medium,
    backgroundColor: colors.white,
    borderRadius: 5,
    height: 42,
    paddingLeft: 20,
    outline: 'none',
    color: colors.purpleDark,
    ':focus': {
      border: `1px solid ${colors.purple}`,
    },
    [mediaQueries.phoneOnly]: {
      width: '100%',
    },
    [mediaQueries.tabletPortraitUp]: {
      width: '100%',
      maxWidth: 465,
    },
    [mediaQueries.tabletLandscapeUp]: {
      width: '100%',
      maxWidth: 585,
    },
  },
  ({ errorStyle }) => ({
    border: errorStyle ? `1px solid red` : `1px solid ${colors.midGrey}`,
  })
);

export const TwoInputWrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    [mediaQueries.phoneOnly]: {
      flexDirection: 'column',
    },
  },
  ({ padding, modal, landing }) => ({
    paddingTop: modal ? 35 : landing ? 20 : padding ? 45 : 0,
    [mediaQueries.phoneOnly]: {
      paddingTop: 30,
    },
  })
);

export const RightInputWrapper = styled.div({
  paddingLeft: 25,
  [mediaQueries.phoneOnly]: {
    paddingLeft: 0,
    paddingTop: 30,
  },
});

export const InputsGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gridColumnGap: '10px',
  gridRowGap: '10px',
  [mediaQueries.phoneOnly]: {
    width: '100%',
  },
  [mediaQueries.tabletPortraitUp]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    width: '100%',
    maxWidth: 465,
  },
  [mediaQueries.tabletLandscapeUp]: {
    width: '100%',
    maxWidth: 545,
  },
});

export const TextareaForm = styled(InputForm.withComponent('textarea'))(
  {
    paddingTop: '18px',
    height: '8em',
    resize: 'none',
    [mediaQueries.phoneOnly]: {
      width: '100%',
    },
    [mediaQueries.tabletPortraitUp]: {
      width: '100%',
      maxWidth: 465,
    },
    [mediaQueries.tabletLandscapeUp]: {
      width: '100%',
      maxWidth: 585,
    },
  },
  ({ errorStyle }) => ({
    border: errorStyle ? `1px solid red` : `1px solid ${colors.midGrey}`,
  })
);

export const NextBtn = styled(Button)(
  {
    marginTop: 50,
    display: 'block',
    color: colors.white,
    minWidth: 133,
    minHeight: 62,
  },
  ({ isFullWidth }) => ({
    [mediaQueries.phoneOnly]: {
      width: isFullWidth ? '100%' : 145,
    },
  }),
  ({ disabledStyle }) => ({
    backgroundColor: disabledStyle ? colors.midGrey : colors.purple,
    '&:before': {
      backgroundColor: disabledStyle ? '' : 'rgba(255,255,255, 0.1)',
    },
    '&:hover': {
      color: disabledStyle ? '' : colors.white,
      cursor: disabledStyle ? 'auto' : 'pointer',
    },
  })
);

export const CloseBtn = styled(AnchorButton)({
  marginTop: 50,
  display: 'block',
  backgroundColor: colors.purple,
  color: colors.white,
  textDecoration: 'none',
  [mediaQueries.phoneOnly]: {
    width: 145,
  },
  '&:before': {
    backgroundColor: 'rgba(255,255,255, 0.1)',
  },
  '&:hover': {
    color: colors.white,
  },
});

export const ErrorMessage = styled.div({
  ...fonts.HKCompakt.medium,
  color: colors.red,
  fontSize: '14px',
  marginTop: '8px',
});

export const SuccessMessage = styled.div({
  ...fonts.HKCompakt.medium,
  color: colors.purple,
  fontSize: '14px',
  marginTop: '8px',
});

//Miscelleanous

export const YellowCircle = styled.span({
  backgroundColor: colors.yellow,
  height: 9,
  width: 9,
  borderRadius: '50%',
  display: 'inline-block',
});

// Form Header

export const FormHeaderContainer = styled.div({
  height: 'auto',
  backgroundColor: colors.white,
  borderRadius: '10px 10px 0px 0px',
  padding: '30px 30px 30px 30px',
  [mediaQueries.tabletLandscapeUp]: {
    height: 145,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 0px 0px 60px',
  },
});

export const FormHeaderSubTitle = styled.p({
  ...fonts.HKCompakt.bold,
  fontSize: 16,
  paddingLeft: 2,
  letterSpacing: '0.2px',
  color: colors.purpleDark,
  [mediaQueries.tabletLandscapeUp]: {
    fontSize: 18,
    letterSpacing: '0.2px',
  },
});

export const FormHeaderMainTitle = styled(HeaderTitle)({
  display: 'block', // before : inline-block
  letterSpacing: '0.6px',
  paddingBottom: 5,
  '&:after': {
    content: '""',
    display: 'inline-block',
    height: 7,
    width: 7,
    marginLeft: 3,
    backgroundColor: colors.yellow,
    borderRadius: '50%',
    [mediaQueries.tabletPortraitUp]: {
      height: 10,
      width: 10,
    },
  },
});

// MultiSelect

export const MultiSelectSmallContainer = styled.div(
  {
    ...fonts.HKCompakt.medium,
    backgroundColor: colors.white,
    fontSize: '16px',
    padding: '11px 10px',
    textAlign: 'center',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 90,
    transitionProperty: 'all',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
    cursor: 'pointer',
    [mediaQueries.phoneOnly]: {
      width: '100%',
      minWidth: 'auto',
    },
    '&:hover': {
      border: `1px solid ${colors.purple}`,
      color: colors.purple,
      backgroundColor: 'rgba(92, 72, 211, 0.2)',
    },
  },
  ({ isSelected }) => ({
    border: isSelected ? `1px solid ${colors.purple}` : `1px solid #DADADA`,
    color: isSelected ? colors.purple : '#DADADA',
  }),
  ({ isLeft }) => ({
    marginLeft: isLeft ? 10 : 0,
  })
);

export const selectStyle = {
  container: (styles) => ({
    ...styles,
    maxWidth: 455,
    [mediaQueries.phoneOnly]: {
      width: '100%',
    },
    [mediaQueries.tabletPortraitUp]: {
      width: '100%',
      minWidth: 220,
    },
    [mediaQueries.tabletLandscapeUp]: {
      minWidth: 280,
    },
  }),
  control: (styles, state, errorStyle) => ({
    ...styles,
    boxShadow: 'none',
    height: 42,
    border: state.isFocused
      ? `1px solid ${colors.purple}`
      : errorStyle
      ? `1px solid red`
      : `1px solid ${colors.midGrey}`,
    borderRadius: 5,
    paddingLeft: 10,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: colors.purpleDark,
  }),
  option: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isFocused ? colors.purple : colors.white,
    color: isDisabled ? colors.midGrey : isFocused ? colors.white : colors.purpleDark,
    paddingLeft: 20,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: colors.purpleDark,
    paddingLeft: 10,
  }),
};

export const selectStyleLarge = {
  container: (styles) => ({
    ...styles,
    maxWidth: 455,
    [mediaQueries.phoneOnly]: {
      width: '100%',
    },
    [mediaQueries.tabletPortraitUp]: {
      width: '100%',
      maxWidth: 465,
    },
    [mediaQueries.tabletLandscapeUp]: {
      width: '100%',
      maxWidth: 585,
    },
  }),
  control: (styles, state, errorStyle) => ({
    ...styles,
    height: 42,
    border: state.isFocused
      ? `1px solid ${colors.purple}`
      : errorStyle
      ? `1px solid red`
      : `1px solid ${colors.midGrey}`,
    borderRadius: 5,
    boxShadow: 'none',
    paddingLeft: 10,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: colors.purpleDark,
  }),
  option: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isFocused ? colors.purple : colors.white,
    color: isDisabled ? colors.midGrey : isFocused ? colors.white : colors.purpleDark,
    paddingLeft: 20,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: colors.purpleDark,
    paddingLeft: 10,
  }),
};

export const InputPhone = styled(PhoneInput)(
  {
    backgroundColor: colors.white,
    borderRadius: 5,
    height: 42,
    paddingLeft: 10,
    paddingTop: 5,
    outline: 'none',
    color: colors.purpleDark,
    '& input': { borderBottom: 'none' },
    '& icon': { border: 'none' },
    '& input:focus': { border: 'none' },
    '& select': { paddingLeft: 10, width: '100%' },
    [mediaQueries.phoneOnly]: {
      minWidth: '100%',
    },
    [mediaQueries.tabletPortraitUp]: {
      minWidth: 220,
    },
    ':focus': {
      border: `1px solid ${colors.purple}`,
    },
  },
  ({ errors, touched, landing }) => ({
    border:
      errors?.phoneNumber && touched?.phoneNumber ? `1px solid red` : `1px solid ${colors.midGrey}`,
    maxWidth: landing ? 'none' : 455,
    [mediaQueries.tabletLandscapeUp]: {
      minWidth: landing ? 'none' : 280,
      width: landing ? 'none' : '100%',
    },
  })
);

export const InputPhoneEmailForm = styled(PhoneInput)({
  backgroundColor: colors.white,
  borderRadius: 5,
  maxWidth: 455,
  height: 42,
  paddingLeft: 10,
  paddingTop: 5,
  outline: 'none',
  color: colors.purpleDark,
  '& input': { borderBottom: 'none', textAlign: 'left', paddingLeft: 4 },
  '& icon': { border: 'none' },
  '& input:focus': { border: 'none' },
  '& select': { paddingLeft: 10, width: '100%' },
  ':focus': {
    border: `1px solid ${colors.purple}`,
  },

  border: `1px solid ${colors.midGrey}`,
});
